import { fork } from 'redux-saga/effects'
import * as saga from "../sagas/sagas"

export default function* rootSaga() {
  yield fork(saga.watchGetHomePage)
  yield fork(saga.watchGetEnvironmentPage)
  yield fork(saga.watchGetGalleryPage)
  yield fork(saga.watchGetGallerySecondaryPage)
  yield fork(saga.watchGetDistributorsPage)
  yield fork(saga.watchGetAboutPage)
  yield fork(saga.watchGetContactPage)
  yield fork(saga.watchGetSearchPage)
  yield fork(saga.watchGetCollectionPage)
  yield fork(saga.watchGetCollectionDetailPage)
  yield fork(saga.watchGetProductPage)
  yield fork(saga.watchGetProductDetailPage)
  yield fork(saga.watchGetCustomPage)
  yield fork(saga.watchGetTags)
}

import * as type from "./constants";
import { homePage } from "../models/homePage";
import { galleryPage } from "../models/galleryPage";
import { distributionPage } from "../models/distributionPage";
import { heritagePage } from "../models/heritagePage";
import { contactPage } from "../models/contactPage";
import { Collections } from "../models/collections";
import { Collection } from "../models/collection";
import { Products } from "../models/products";
import { Product } from "../models/product";
import { Tags } from "../models/filterTags";

export interface InitialState {
  apiLoading: boolean;
  error: string | null;
  homepageData: homePage | null;
  environmentPage: any;
  galleryPage: galleryPage | null;
  gallerySecondaryPage: galleryPage | null;
  distributionPage: distributionPage | null;
  heritagePage: heritagePage | null;
  contactPage: contactPage | null;
  searchPage: any;
  collections: Collections | null;
  collection: Collection | null;
  products: Products | null;
  product: Product | null;
  customPage: any;
  tags: Tags[] | null
}

const initialState: InitialState = {
  apiLoading: false,
  error: null,
  homepageData: null,
  environmentPage: null,
  galleryPage: null,
  gallerySecondaryPage: null,
  distributionPage: null,
  heritagePage: null,
  contactPage: null,
  searchPage: null,
  collections: null,
  collection: null,
  products: null,
  product: null,
  customPage: null,
  tags: null
};

export default function apiReducer(state = initialState, action) {
  switch (action.type) {
    case type.API_START: {
      return {
        ...state,
        apiLoading: true,
        error: null,
      };
    }
    case type.API_END: {
      return {
        ...state,
        apiLoading: false,
      };
    }
    case type.API_ERROR: {
      return {
        ...state,
        apiLoading: false,
        error: action.error,
      };
    }
    case type.GET_HOME: {
      return {
        ...state,
        homepageData: action.payload,
      };
    }
    case type.GET_ENVIRONMENT: {
      return {
        ...state,
        environmentPage: action.payload,
      };
    }
    case type.GET_GALLERY: {
      return {
        ...state,
        galleryPage: action.payload,
      };
    }
    case type.GET_GALLERY_SECONDARY: {
      return {
        ...state,
        gallerySecondaryPage: action.payload,
      };
    }
    case type.GET_DISTRIBUTORS: {
      return {
        ...state,
        distributionPage: action.payload,
      };
    }
    case type.GET_ABOUT: {
      return {
        ...state,
        heritagePage: action.payload,
      };
    }
    case type.GET_CONTACT: {
      return {
        ...state,
        contactPage: action.payload,
      };
    }
    case type.GET_SEARCH: {
      return {
        ...state,
        searchPage: action.payload,
      };
    }
    case type.GET_COLLECTION: {
      return {
        ...state,
        collections: action.payload,
      };
    }
    case type.GET_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }
    case type.GET_CUSTOM_PAGE: {
      return {
        ...state,
        customPage: action.payload,
      };
    }
    case type.GET_TAGS: {
      return {
        ...state,
        tags: action.payload,
      };
    }
    case type.GET_COLLECTION_DETAIL: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    case type.GET_PRODUCTS_DETAIL: {
      return {
        ...state,
        product: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

import React, { useEffect, Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { useInjectSaga } from "../../utils/injectSaga";
import rootSaga from "../../redux/sagas/rootSaga";
import { watchGetHomePage } from "../../redux/actions";
import { watchGetProductsPage } from "../../redux/actions";
import { useMediaQuery } from "react-responsive";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MobileHeader from "../../components/HeaderMobile";

import GlobalStyle from "../../global-styles";
import {CartProvider} from "../../contexts/cartContext";


const HomePage = lazy(() => import("../HomePage/Loadable"));
const CollectionPage = lazy(() => import("../CollectionPage"));
const EnvironmentPage = lazy(() => import("../EnvironmentPage"));
const GalleryPage = lazy(() => import("../GalleryPage"));
const GallerySecondaryPage = lazy(() => import("../GallerySecondaryPage"));
const DistributionPage = lazy(() => import("../DistributionPage"));
const HeritagePage = lazy(() => import("../HeritagePage"));
const ContactPage = lazy(() => import("../ContactPage"));
const SearchResultsPage = lazy(() => import("../SearchResultsPage"));
const CustomerCartPage = lazy(() => import("../CustomerCart"));
const CustomerAddressPage = lazy(() => import("../CustomerAddress"));
const CustomerShippingPage = lazy(() => import("../CustomerShippingPage"));
const CustomerPaymentPage = lazy(() => import("../CustomerPayment"));
const ReceiptPage = lazy(() => import("../ReceiptPage"));
const CustomPage = lazy(() => import("../CustomPage"));
const ProductCategoryPage = lazy(() => import("../ProductCategoryPage"));
const NotFound = lazy(() => import("../Errors"));

function App({ watchGetHomePage, watchGetProductsPage }) {
  useInjectSaga({ key: "apiReducer", saga: rootSaga });
  useEffect(() => {
    //HTTP GET API
    watchGetHomePage();
    watchGetProductsPage();
  });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });

  return (
    <CartProvider>
      <div>
        {!isTabletOrMobile ? <Header/> : <MobileHeader/>}
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route exact path="/collections/:id" component={CollectionPage}/>
            <Route exact path="/pro-shop" component={ProductCategoryPage}/>
            <Route exact path="/pro-shop/product-category/:id" component={ProductCategoryPage}/>
            <Route path="/pro-shop/product-category/:id/:subId" component={CollectionPage}/>
            <Route exact path="/page/:id" component={CustomPage}/>
            <Route exact path="/environment" component={EnvironmentPage}/>
            <Route exact path="/gallery" component={GalleryPage}/>
            <Route exact path="/gallery-other" component={GallerySecondaryPage}/>
            <Route exact path="/distributors" component={DistributionPage}/>
            <Route exact path="/about" component={HeritagePage}/>
            <Route exact path="/contact" component={ContactPage}/>
            <Route exact path="/search/:query" component={SearchResultsPage}/>
            <Route exact path="/cart/" component={CustomerCartPage}/>
            <Route exact path="/cart/customer-info/" component={CustomerAddressPage}/>
            <Route exact path="/cart/shipping/" component={CustomerShippingPage}/>
            <Route exact path="/cart/billing/" component={CustomerPaymentPage}/>
            <Route exact path="/orders/receipt/" component={ReceiptPage}/>
            <Route exact path='*' component={NotFound}/>
          </Switch>
        </Suspense>
        <Footer/>
        <GlobalStyle/>
      </div>
    </CartProvider>
  );
}

export default connect(null, { watchGetHomePage, watchGetProductsPage })(App);

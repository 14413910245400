import * as type from "./constants";

// API Status actions
export function apiStart() {
  return {
    type: type.API_START,
  };
}

export function apiEnd() {
  return {
  type: type.API_END,
  }
};

export function apiError() {
  return {
  type: type.API_ERROR,
  }
};

// API Endpoint actions
export function getHomePage(payload) {
  return {
    type: type.GET_HOME,
    payload: payload
  };
}

export function getEnvironmentPage(payload) {
  return {
    type: type.GET_ENVIRONMENT,
    payload: payload
  };
}

export function getGalleryPage(payload) {
  return {
    type: type.GET_GALLERY,
    payload: payload
  };
}

export function getGallerySecondaryPage(payload) {
  return {
    type: type.GET_GALLERY_SECONDARY,
    payload: payload
  };
}

export function getDistributorsPage(payload) {
  return {
    type: type.GET_DISTRIBUTORS,
    payload: payload
  };
}

export function getAboutPage(payload) {
  return {
    type: type.GET_ABOUT,
    payload: payload
  };
}

export function getContactPage(payload) {
  return {
    type: type.GET_CONTACT,
    payload: payload
  };
}

export function getSearchPage(payload) {
  return {
    type: type.GET_SEARCH,
    payload: payload
  };
}

export function getCollectionPage(payload) {
  return {
    type: type.GET_COLLECTION,
    payload: payload
  };
}

export function getCollectionDetailPage(payload) {
  return {
    type: type.GET_COLLECTION_DETAIL,
    payload: payload
  };
}

export function getProductPage(payload) {
  return {
    type: type.GET_PRODUCTS,
    payload: payload
  };
}

export function getProductDetailPage(payload) {
  return {
    type: type.GET_PRODUCTS_DETAIL,
    payload: payload
  };
}

export function getCustomPage(payload) {
  return {
    type: type.GET_CUSTOM_PAGE,
    payload: payload
  }
}

export function getTags(payload) {
  return {
    type: type.GET_TAGS,
    payload: payload
  }
}

// API watcher actions
export function watchGetHomePage() {
  return {
    type: type.WATCH_HOME_PAGE,
  };
}

export function watchGetEnvironmentPage() {
  return {
    type: type.WATCH_ENVIRONMENT_PAGE,
  };
}

export function watchGetGalleryPage() {
  return {
    type: type.WATCH_GALLERY_PAGE,
  };
}

export function watchGetGallerySecondaryPage() {
  return {
    type: type.WATCH_GALLERY_SECONDARY_PAGE,
  };
}

export function watchGetDistributorsPage(searchTerm) {
  return {
    type: type.WATCH_DISTRIBUTORS_PAGE,
    searchTerm
  };
}


export function watchGetAboutPage() {
  return {
    type: type.WATCH_ABOUT_PAGE,
  };
}

export function watchGetContactPage() {
  return {
    type: type.WATCH_CONTACT_PAGE,
  };
}

export function watchGetSearchPage(searchTerm) {
  return {
    type: type.WATCH_SEARCH_PAGE,
    searchTerm
  };
}

export function watchGetCollectionPage() {
  return {
    type: type.WATCH_COLLECTION_PAGE
  };
}

export function watchGetCollectionDetailPage(slug) {
  return {
    type: type.WATCH_COLLECTION_DETAIL_PAGE,
    slug
  };
}

export function watchGetProductsPage() {
  return {
    type: type.WATCH_PRODUCTS_PAGE
  };
}

export function watchGetProductsDetailPage(slug) {
  return {
    type: type.WATCH_PRODUCTS_DETAIL_PAGE,
    slug
  };
}

export function watchGetCustomPage(slug) {
  return {
    type: type.WATCH_CUSTOM_PAGE,
    slug
  }
}

export function watchGetTags() {
  return {
    type: type.WATCH_TAGS,
  }
}

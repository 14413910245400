// @ts-nocheck
import React, {createContext, useState, useEffect} from "react";

const CartContext = createContext();


const CartProvider = ({ children }) => {
  const [customerCart, setCustomerCart] = useState([])
  const [customerAddress, setCustomerAddress] = useState({})
  const [shippingCost, setShippingCost] = useState(0)
  const [shippingOptionName, setShippingOptionName] = useState('');

  // persist cart on refresh or session close
  useEffect(() => {
    if (localStorage.getItem('customer-cart') !== null) {
      setCustomerCart(JSON.parse(localStorage.getItem('customer-cart')))
    }

    // shippingOption
    if (localStorage.getItem('customer-shipping') !== null) {
      setShippingCost(localStorage.getItem('customer-shipping'))
    } else {
      localStorage.setItem('customer-shipping', '0')
      setShippingCost(0)
    }

    if (localStorage.getItem('selected-shipping') !== null && localStorage.getItem('customer-shipping') !== null && localStorage.getItem('customer-shipping') !== '0') {
      setShippingOptionName(localStorage.getItem('selected-shipping'))
    } else {
      setShippingOptionName(localStorage.setItem('selected-shipping', ''))
    }

  }, [])


  const addToCart = (cartItem, qty = 1, options = null) => {
    let colorOptionSelected = false

    // base product attributes
    const cartObject = {
      id: cartItem.id,
      name: cartItem.CollectionTitle,
      brand: cartItem.CollectionName,
      qty: qty,
      cost: qty * cartItem.ProductCost,
      baseCost: cartItem.ProductCost,
      productImage: cartItem.Logo?.url,
      multiPack: cartItem.multiPack,
      length: cartItem.itemLength,
      width: cartItem.itemWidth,
      height: cartItem.itemHeight,
      weight: cartItem.itemWeight
    }

    // check if item added from non-detail page has options, if so select first in list
    if (cartItem.hasOwnProperty('ColorOptions') && cartItem?.ColorOptions?.Color.length > 0) {
      colorOptionSelected = true
      cartObject['colorOption'] = cartItem?.ColorOptions?.Color[0]

      // assume options
      if (options === null) {
        options = {
          colorOption: cartItem?.ColorOptions?.Color[0].Name
        }
      }

    }

    if (options !== null) {
      // DISCOUNT - change base cost and total cost to discounted price
      if (options.hasOwnProperty('applyDiscount') && options.discountPrice > 0) {
        cartObject.baseCost = options.discountPrice
        cartObject.cost = parseFloat(cartObject.qty * options.discountPrice).toFixed(2)
      }

      // COLOR - add user selected color option
      if (options.hasOwnProperty('colorOption') && options.colorOption !== undefined) {
        cartObject['colorOption'] = options.colorOption
        colorOptionSelected = true
      }
    }


    setCustomerCart(prevState => {
      const newState = [...prevState]

      // default action, add product to cart and update qty if needed
      if (!colorOptionSelected) {
        if (newState.filter(exItems => exItems.id === cartObject.id).length > 0) {
          let modifyCartItem = newState.filter(exItems => exItems.id === cartObject.id)[0]
          modifyCartItem.qty += qty
          modifyCartItem.cost = parseFloat((modifyCartItem.baseCost * modifyCartItem.qty).toFixed(2))
        } else {
          newState.push(cartObject)
        }
      }

      // customer chose a color option, update selected item qty based on color
      if (colorOptionSelected) {
        if (newState.filter(exItems => exItems.id === cartObject.id && exItems.colorOption === options.colorOption).length > 0) {
          let modifyCartItem = newState.filter(exItems => exItems.id === cartObject.id && exItems.colorOption === options.colorOption)[0]
          modifyCartItem.qty += qty
          modifyCartItem.cost = parseFloat((modifyCartItem.baseCost * modifyCartItem.qty).toFixed(2))
        } else {
          newState.push(cartObject)
        }
      }

      console.log('ItemAdded', newState)
      localStorage.setItem('customer-cart', JSON.stringify(newState));
      return newState
    })
  }

  const removeFromCart = (item) => {
    setCustomerCart(prevState => {
      let newState = [...prevState]

      newState = newState.filter(exItems => exItems !== item)

      console.log('ItemRemoved', newState)
      localStorage.setItem('customer-cart', JSON.stringify(newState));
      return newState
    })
  }

  const changeProductQuantity = (itemId, newQty) => {
    setCustomerCart(prevState => {
      const newState = [...prevState]

      let selectedItem = newState.filter(exItems => exItems === itemId)[0]

      selectedItem.qty = parseInt(newQty)
      selectedItem.cost = (parseInt(newQty) * selectedItem.baseCost).toFixed(2)

      console.log('QtyChanged', newState)
      localStorage.setItem('customer-cart', JSON.stringify(newState));
      return newState
    })
  }

  const cartItemCount = () => {
    let cartCount = 0
    customerCart.forEach(item => {
      cartCount += item.qty
    })

    return cartCount
  }

  const cartTotal = () => {
    let cartTotal = 0.00

    if (customerCart.length === 0) {
      return 0.00
    }

    customerCart.forEach(item => {
      cartTotal += parseFloat(item.cost)
    })

    return (parseFloat(cartTotal)).toFixed(2)
  }

  const cartTotalFinal = () => {
    let cartTotal = 0.00

    if (customerCart.length === 0) {
      return 0.00
    }

    customerCart.forEach(item => {
      cartTotal += parseFloat(item.cost)
    })

    if (parseFloat(shippingCost) > 0) {
      cartTotal += parseFloat(shippingCost)
    }

    return (parseFloat(cartTotal)).toFixed(2)
  }

  const storeCustomerAddress = (customerInfoObj) => {
    setCustomerAddress(prevState => {
      const newState = customerInfoObj

      // handle billing same as shipping
      if (customerInfoObj.billing_same_as_shipping) {
        customerInfoObj.billing_address = customerInfoObj.shipping_address
        customerInfoObj.billing_city = customerInfoObj.shipping_city
        customerInfoObj.billing_email = customerInfoObj.shipping_email
        customerInfoObj.billing_first_name = customerInfoObj.shipping_first_name
        customerInfoObj.billing_last_name = customerInfoObj.shipping_last_name
        customerInfoObj.billing_state = customerInfoObj.shipping_state
        customerInfoObj.billing_zip = customerInfoObj.shipping_zip
      }

      localStorage.setItem('customer-info', JSON.stringify(newState));
      return newState
    })
  }

  const customerSelectedShipping = (shippingOption) => {
    localStorage.setItem('customer-shipping', shippingOption);
    setShippingCost(shippingOption)
  }

  const customerChosenShipMethod = (shippingOption) => {
    localStorage.setItem('selected-shipping', shippingOption);
    setShippingOptionName(shippingOption)
  }

  const removeCustomerSelectedShipping = () => {
    localStorage.removeItem('customer-shipping');
    setShippingCost(0)
  }

  return (
    <CartContext.Provider value={{
      customerCart,
      setCustomerCart,
      addToCart,
      cartItemCount,
      removeFromCart,
      changeProductQuantity,
      cartTotal,
      cartTotalFinal,
      storeCustomerAddress,
      setShippingCost,
      shippingCost,
      customerSelectedShipping,
      removeCustomerSelectedShipping,
      shippingOptionName,
      setShippingOptionName,
      customerChosenShipMethod }}>
      {children}
    </CartContext.Provider>
  )

}

export { CartContext, CartProvider };

import React, {useEffect, useState} from "react";
import usaLogo from "../../assets/images/usa-logo.jpg";
import americanHardwoodLogo from "../../assets/images/american-hardwood-logo.jpg";
import certifiedLogo from "../../assets/images/certified-logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function Footer({ apiLoading, homepageData }) {
  const [productMenuItems, setProductMenuItems] = useState([])
  const [resourceMenuItems, setResourceMenuItems] = useState([])
  const [companyMenuItems, setCompanyMenuItems] = useState([])

  useEffect(() => {
    if (homepageData) {
      const { FooterMenu } = homepageData
      setProductMenuItems(FooterMenu.filter(i => i.Placement === 'Product'))
      setResourceMenuItems(FooterMenu.filter(i => i.Placement === 'Resources'))
      setCompanyMenuItems(FooterMenu.filter(i => i.Placement === 'Company'))
    }
  }, [homepageData])


  return (
    <footer className="footer-sec">
      <div className="footer-container">
        <div className="row">
          <div className="footer-site-logo col-12 col-lg-5">
            <img src={homepageData?.SiteLogo?.url} alt=""/>
            <p dangerouslySetInnerHTML={{ __html: homepageData?.FooterContent?.FooterText }}></p>
            <p>Toll Free: {homepageData?.ContactNumber}<br/>Office: {homepageData?.ContactNumber2}<br/>Fax: {homepageData?.FaxNumber}</p>
            <p>{homepageData?.FooterContent?.AddressLine1}<br/>{homepageData?.FooterContent?.AddressLine2}</p>
          </div>
          <div className="footer-right d-none d-lg-block col-lg-6">
            <div className="social-icons">
              <ul>
                {homepageData?.SocialNetworks?.map((media, id) => (
                  <li key={id}>
                    <a href={media.Link}>
                      {media?.DisplayName?.toLowerCase() !== "instagram" ? (
                        <i
                          className={`fa fa-${media?.DisplayName?.toLowerCase()}-square fa-2x`}
                        ></i>
                      ) : (
                        <FontAwesomeIcon icon={faInstagramSquare} size="2x"/>
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="quick-links">
                <div className="product-quick-links">
                  <p>Product</p>
                  <ul>
                    {productMenuItems?.map((menu, index) =>
                      <li><a href={menu?.linkURL}>{menu?.linkText}</a></li>
                    )}
                  </ul>
                </div>
                <div className="resources-quick-links">
                  <p>Resources</p>
                  <ul>
                    {resourceMenuItems?.map((menu, index) =>
                      <li><a href={menu?.linkURL}>{menu?.linkText}</a></li>
                    )}
                  </ul>
                </div>
                <div className="company-quick-links">
                  <p>Company</p>
                  <ul>
                    {companyMenuItems?.map((menu, index) =>
                      <li><a href={menu?.linkURL}>{menu?.linkText}</a></li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'copyrightContainer'}>
        <div className="row">
          <div className="col-12">
            <div className="footer-inenr">
              <div className="text-box">
              </div>
              <div className="copyright row">
                <div className="copyright-text col-12 col-lg-6">
                <p>
                  copyright ©2023 Oak floor supply, Inc&nbsp;&bull;&nbsp;<span>Privacy Policy</span>
                </p>
                </div>
                <div className="col-12 col-lg-6 doc-4-badge">
                  <a href="https://doc4design.com/">designed in arkansas</a>
                  <p>&nbsp;by doc4</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  apiLoading: PropTypes.bool,
  homepageData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  apiLoading: state.apiReducer.apiLoading,
  homepageData: state.apiReducer.homepageData,
});

export default connect(mapStateToProps)(Footer);

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';



const API_SETTINGS = {
  prod: 'https://api.oakfloorsupply.doc4.work',
  dev: 'http://127.0.0.1:1337'
}


export const API_BASE_URL = API_SETTINGS.prod
